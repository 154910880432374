<template>
	<div>
		<v-data-table
			:headers="requestHeaders"
			:items="requestList"
			:items-per-page="15"
			:loading="isLoading"
			@click:row="handleClick"
			:item-class="itemRowBackground"
			class="elevation-1 request_list">
			<template v-slot:item.decision="{ item }">
				{{ decision[item.decision] }}
			</template>
		</v-data-table>
	</div>
</template>

<script>
	export default {
        mounted() {
            this.getData();
        },
		data: () => ({
			isLoading: true,
			decision: ['Нет решения', 'Положительно', 'Отрицательно'],
			requestHeaders: [],
			requestList: []
		}),
		methods: {
			handleClick(link) {
				this.$router.push({ path: '/kindergarten/doc-request/'+link.id });
			},
			itemRowBackground: function (item) {
				let rowCLass = '';
				if(item.decision == '1') {
					rowCLass = 'light-green lighten-4';
				} else if(item.decision == '2') {
					rowCLass = 'red lighten-5';
				}
				return rowCLass;
			}, 
			async getData() {
				this.isLoading = true;
				await this.$http.get('/request/acceptance/all').then((response) => {
					this.requestHeaders = response.data.data.requestAcceptanceList.headers;
					this.requestList = response.data.data.requestAcceptanceList.list;
				}).catch(() => {});
				this.isLoading = false;
			}
			
		}
  }
</script>

<style scoped>
	/*.request_list tr {
		cursor: pointer !important;
	}*/
</style>